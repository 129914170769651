var firstLoad = true;
var thumbsBlockHeight = 0;
var loaded = false;

var app = {
	 widget: null,
   init: function(href) {
		if (firstLoad) {
			$(document).on('click', '#menu_button', function () {
				$('body').toggleClass('open');
			});

			$('#main').scroll(function(e) {
				var delta = previousScrollTop - $(this).scrollTop();
				previousScrollTop = $(this).scrollTop();

				if (delta < 0 && $(this).scrollTop() > 60) {
					$('#bar').addClass('overthetop');
				} else if (delta > 0) {
					$('#bar').removeClass('overthetop');
				}

				var fromTop = $(this).scrollTop();
				var paddingTop = Math.floor(fromTop / 2);

				if (paddingTop < 0) paddingTop = 0;

				$('#thumbs').css({
					'margin-top': paddingTop
				});

				if ($('.post-loader').length) {
					var loaderPosition = $('.post-loader').position().top;
					if (!window.loading && loaderPosition < $(window).height()) {
						loadPage();
					}
				}
			});
		}

		if ($('#thumbs').length) {
	 	  	$('#thumbs').slick({
					infinite: true,
					autoplaySpeed: 5000,
					autoplay: true,
					arrows: false,
					dots: true
				});

				thumbsBlockHeight = $('#thumbs img').height();

				$('#thumbs iframe').height(thumbsBlockHeight);
				$('.thumbs-container').height(thumbsBlockHeight);

				$('#thumbs .thumb img').on('load', function(){
					thumbsBlockHeight = $('#thumbs img').height();
					$('#thumbs iframe').height(thumbsBlockHeight);
					$('.thumbs-container').height(thumbsBlockHeight);
				});
			}

		$('#_nav_list a').removeClass('current');
		if (window.location.pathname == '/') {
			 $('#_nav_list a').first().addClass('current');
		}

		if (href.indexOf('#') != -1) {
			 var hash = href.substr(href.indexOf('#'));

			 if ($(hash).length) {
				$('#main').stop().scrollTop(0);
			 	setTimeout(function(){
			 		$('#main').stop().animate({scrollTop: $(hash).position().top - 50}, '500', 'swing');
			 	}, 500);
			}
		} else {
			 if (!firstLoad) {
					$('#main').stop().animate({scrollTop: 0}, '500', 'swing');
			 }
			 firstLoad = false;
		}
   }
};

$(document).ready(function(){
   app.init(window.location.href);
});

var previousScrollTop = 0;
var loading = false;
var currentPage = 1;

function loadPage(){
   if (!window.loading) {
        window.loading = true;
        window.currentPage++;
        $.ajax({
            url: '/wp-admin/admin-ajax.php',
            data: {page:  window.currentPage, action: 'load_page'},
            dataType: 'JSON',
            beforeSend: function(){
                $('.post-loader').animate({opacity: 1});
            },success: function(res) {
                if (res.status == 'ok') {
                    $('.post-loader').before(res.html);
                    $('.post-loader').css({opacity: 0});
                } else {
                    $('.post-loader').remove();
                }
                setTimeout(function(){
                    window.loading = false;
                }, 1000);
            }
        });
   }
}

function onLoad(){
   if (!loaded) {
		 if (window.location.hash == '') {
			 $('#main').scrollTop(thumbsBlockHeight);
		 }
		 $('.loading').fadeOut(500, function () {
			 $('#nav').removeClass('hidden');
			 if (window.location.hash == '') {
				 setTimeout(function () {
					 $('#main').stop().animate({scrollTop: 0}, '500', 'swing', function () {
						 // Nothing
					 });
				 }, 500);
			 }
		 });
		 loaded = true;
	 }
}

$(window).on( "load", function(){
	onLoad();
});

setTimeout(function(){
	onLoad();
}, 5000);

$(document).on('click', 'a:not([href^="#"], [href^="mailto:"], [href^="tel:"], [data-fancybox], [data-index])', function(){
   var href = $(this).attr('href');

   var hostCheck = new RegExp(location.host);
   if(href.indexOf('http') == 0 && !hostCheck.test(href)) {
      return true;
   }

   if ($(this).parents('#wpadminbar').length) {
      return true;
   }

   $('.loader').css({
      'margin-left': '100px'
   });

   $('#main').addClass('progress');

   setTimeout(function() {
      $('.loading').show();
      $.ajax({
         url: href,
         success: function(html){
            $('.loading').fadeOut(function(){
               var newContent = $(html).filter('#main').html();
							 var newTitle = $(html).filter('title').text();
               $('#main').html(newContent);

               $('#thumbs iframe').height(thumbsBlockHeight);
               $('.thumbs-container').height(thumbsBlockHeight);

               $('#main').removeClass('progress');
               window.history.pushState(null, null, href);
               document.title = newTitle;
               app.init(href);
            });
         }
      });
   }, 500);

   return false;
});
